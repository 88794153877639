export const navLinks = [
{
    id: "home",
    title: "Home",
},
{
    id: "about",
    title: "About",
},
{
    id: "services",
    title: "Services",
},
{
    id: "works",
    title: "Works",
},
{
    id: "contact",
    title: "Contact",
}
]