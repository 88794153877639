import About from "./components/About";
import AdsCard from "./components/AdsCard";
import CardDetails from "./components/CardDetails";
import ChoosingUs from "./components/ChoosingUs";
import Clients from "./components/Clients";
import Features from "./components/Features";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
import WorkSteps from "./components/WorkSteps";

function App() {
  return (
    <div className="bg-gray-50 w-full">
      <Navbar />
      <Hero />
      {/* <AdsCard /> */}
      {/* <About /> */}
      <Services />
      <WorkSteps />
      <Features />
      <CardDetails />
      <ChoosingUs />
      <Clients />
    </div>
  );
}

export default App;
